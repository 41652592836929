import { API, getAuthorization } from "./axiosConfig";

const index = (search_by, per_page, page) => {
  API.defaults.headers.Authorization = getAuthorization();
  const config = {
    params: {
      search_by: search_by,
      per_page: per_page,
      page: page,
    },
  };
  return API.get("trips", config);
};

const show = (id_trip) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.get(`trips/${id_trip}`);
};

const total_items = (search_by) => {
  API.defaults.headers.Authorization = getAuthorization();
  const config = {
    params: {
      search_by: search_by,
    },
  };
  return API.get("trips/total-items", config);
};

const index_spots = (id_trip) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.get(`trips/${id_trip}/spots`);
};

const create = (params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.post("trips", params);
};

const cancel = (id_trip) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.patch(`trips/${id_trip}/cancel`);
};

export { index, show, total_items, index_spots, create, cancel };
