<template>
  <div id="title-section-container">
    <div id="main-container">
      <div id="title-container">
        <h1>{{ title }}</h1>
        <div class="loading">
          <img v-if="loading.length > 0" src="@/assets/img/loading.gif" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleComponent",
  props: { title: String, loading: Array },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#title-section-container {
  width: 100%;
  height: auto;
}

#main-container {
  width: 100%;
  height: auto;
  margin-bottom: 2em;
  background-color: transparent;
}

#title-container {
  display: flex;
  align-items: center;
  padding-bottom: 0.8em;
  border-bottom: 2px solid var(--line);
}

h1 {
  width: auto;
  margin-right: 1em;
  font-size: 1.5em;
  color: var(--light);
}

.loading {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
</style>
