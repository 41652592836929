<template>
  <div id="new-reservation-payment-container">
    <div id="main-container">
      <Nav navOptionChosen="reservations"></Nav>
      <section class="main-section">
        <TitleSection title="Nuevo pago" :loading="loading"></TitleSection>
        <form class="col-between" @submit.prevent="create">
          <div>
            <p class="top-left-text mb2em">
              Reservación de {{ reservation.customer_name }}
            </p>
            <div class="form-control">
              <label class="form-label" for="amount">Cantidad</label>
              <input
                id="amount"
                class="center input input-number"
                type="number"
                step="0.01"
                placeholder="100"
                v-model="amount"
              />
            </div>
            <div class="form-control">
              <label class="form-label">Método de pago</label>
              <div class="radios-group">
                <div
                  class="input-radio center"
                  :class="{ 'input-radio-checked': payment_type === 'CASH' }"
                  @click="payment_type = 'CASH'"
                >
                  Efectivo
                </div>
                <div
                  class="input-radio center"
                  :class="{
                    'input-radio-checked': payment_type === 'CASH_DEPOSIT',
                  }"
                  @click="payment_type = 'CASH_DEPOSIT'"
                >
                  Depósito
                </div>
                <div
                  class="input-radio center"
                  :class="{ 'input-radio-checked': payment_type === 'OTHER' }"
                  @click="payment_type = 'OTHER'"
                >
                  Otro
                </div>
              </div>
            </div>
          </div>
          <div>
            <input
              class="input btn btn-submit"
              type="submit"
              :value="btnValue"
              :disabled="submitDisabled"
            />
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../../components/Nav.vue";
import TitleSection from "../../../components/TitleSection.vue";

// Services
import { show } from "@/services/reservations";
import { create } from "@/services/reservationPayments";

export default {
  name: "NewTripBillView",
  data: function () {
    return {
      loading: [],
      reservation: { full_name: "" },
      amount: "",
      payment_type: "CASH",
      btnValue: "Guardar",
      submitDisabled: true,
    };
  },
  created() {
    this.loadReservation();
  },
  methods: {
    loadReservation: async function () {
      try {
        const response = await show(this.$route.params.id);
        this.reservation = response.data.data;
      } catch (err) {
        console.log(err);
        this.errorCreate(err);
      } finally {
        this.submitDisabled = false;
        this.btnValue = "Guardar";
        this.loading.pop();
      }
    },
    create: async function () {
      let params = this.getCreateParams();
      if (params === false) return;
      this.submitDisabled = true;
      this.btnValue = "Guardando";
      this.loading.push(true);
      try {
        await create(this.$route.params.id, params);
        this.$router.push({
          name: "reservation-payments",
          params: { id: this.$route.params.id },
        });
      } catch (err) {
        this.errorCreate(err);
      } finally {
        this.submitDisabled = false;
        this.btnValue = "Guardar";
        this.loading.pop();
      }
    },
    errorCreate: function (err) {
      console.log(err);
      let error = "";
      if (err.response) {
        switch (err.response.data.error) {
          case "Payment overpays the total of the reservation":
            error = "El pago excede el total de la reservación";
            break;
        }
      } else if (err.request)
        error = "El servidor no ha respondido a la solicitud";
      else error = "Ha ocurrido un error inesperado";
      this.$swal.fire({
        title: "La acción no pudo ser completada",
        text: error,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    },
    getCreateParams: function () {
      if (this.amount == "") {
        this.$swal.fire({
          title: `Complete todos los campos para continuar`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      let params = {
        amount: parseFloat(this.amount),
        payment_type: this.payment_type,
      };
      if (!/^\d+(\.\d+)?$/.test(params.amount)) {
        this.$swal.fire({
          title: `La cantidad "${params.amount}" es inválida`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      return params;
    },
  },
  components: {
    Nav,
    TitleSection,
  },
};
</script>

<style lang="scss" scoped>
#new-reservation-payment-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}

form {
  min-height: calc(100vh - 3em - 2em - 0.8em - 2em);
  position: relative;
  color: var(--light);
}
</style>
