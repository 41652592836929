<template>
  <div id="new-reservation-container">
    <div id="main-container">
      <Nav navOptionChosen="reservations"></Nav>
      <section class="main-section col-between">
        <TitleSection
          title="Nueva reservación"
          :loading="loading"
        ></TitleSection>
        <form @submit.prevent="create">
          <div>
            <div class="form-control">
              <label class="form-label" for="first-name">Cliente</label>
              <input
                class="center input input-text"
                type="text"
                placeholder="Nombre"
                list="customers"
                v-model="customerName"
              />
              <datalist id="customers">
                <option v-for="customer in customers" :key="customer.id">
                  {{ customer.full_name }} ({{ customer.phone }})
                </option>
              </datalist>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Viaje</label>
              <input
                class="center input input-text"
                type="text"
                placeholder="Nombre"
                list="trips"
                v-model="tripName"
              />
              <datalist id="trips">
                <option v-for="trip in trips" :key="trip.id">
                  {{ trip.name }} ({{ trip.f_departure_date.substring(0, 10) }})
                </option>
              </datalist>
            </div>
            <div class="form-control">
              <label class="form-label">Adultos</label>
              <NumberSelectorComponent
                :number="adultSpots"
                :minValue="0"
                @add-number="(num) => (adultSpots += num)"
              >
              </NumberSelectorComponent>
            </div>
            <div class="form-control">
              <label class="form-label">Menores</label>
              <NumberSelectorComponent
                :number="underageSpots"
                :minValue="0"
                @add-number="(num) => (underageSpots += num)"
              >
              </NumberSelectorComponent>
            </div>
            <div class="form-control">
              <label class="form-label">Lugares</label>
              <div class="spots-container">
                <div
                  class="spot-number center"
                  :class="[
                    !spot.is_available
                      ? 'busy-spot'
                      : spotIDsChosen.includes(spot.id)
                      ? 'selected-spot'
                      : 'free-spot',
                  ]"
                  v-for="spot in spots"
                  :key="spot.id"
                  @click="selectSpot(spot.is_available, spot.id)"
                >
                  {{ spot.spot_number }}
                </div>
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Descuento</label>
              <div>
                <input
                  class="center input input-number w10em"
                  type="number"
                  v-model="discountPercentage"
                />
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">
                Medio de reservación
              </label>
              <div class="radios-group">
                <div
                  class="input-radio center"
                  :class="{
                    'input-radio-checked': reservationMeans === 'Whatsapp',
                  }"
                  @click="reservationMeans = 'Whatsapp'"
                >
                  Whatsapp
                </div>
                <div
                  class="input-radio center"
                  :class="{
                    'input-radio-checked': reservationMeans === 'Facebook',
                  }"
                  @click="reservationMeans = 'Facebook'"
                >
                  Facebook
                </div>
                <div
                  class="input-radio center"
                  :class="{
                    'input-radio-checked': reservationMeans === 'Oficina',
                  }"
                  @click="reservationMeans = 'Oficina'"
                >
                  Oficina
                </div>
                <div
                  class="input-radio center"
                  :class="{
                    'input-radio-checked': reservationMeans === 'Otro',
                  }"
                  @click="reservationMeans = 'Otro'"
                >
                  Otro
                </div>
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">
                Lugar de partida
              </label>
              <div>
                <input
                  class="center input input-text"
                  type="text"
                  v-model="departurePlace"
                />
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">
                Lugar de llegada
              </label>
              <div>
                <input
                  class="center input input-text"
                  type="text"
                  v-model="arrivalPlace"
                />
              </div>
            </div>
          </div>
          <input
            class="input btn btn-submit"
            type="submit"
            :value="btnValue"
            :disabled="submitDisabled"
          />
        </form>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";
import NumberSelectorComponent from "../../components/NumberSelector.vue";

// Services
import { create } from "@/services/reservations";
import { index as index_customers } from "@/services/customers";
import { index as index_trips, index_spots } from "@/services/trips";

export default {
  name: "NewReservationView",
  data: function () {
    return {
      loading: [],
      customers: [],
      customerName: "",
      customerId: null,
      trips: [],
      tripName: "",
      tripId: null,
      adultSpots: 0,
      underageSpots: 0,
      spots: [],
      spotIDsChosen: [],
      discountPercentage: 0,
      reservationMeans: "Whatsapp",
      departurePlace: "",
      arrivalPlace: "",
      btnValue: "Guardar",
      submitDisabled: false,
    };
  },
  watch: {
    customerName: async function () {
      await this.loadCustomers();
      let customerFound = false;
      for (let customer of this.customers) {
        if (`${customer.full_name} (${customer.phone})` === this.customerName) {
          this.customerId = customer.id;
          customerFound = true;
          break;
        }
      }
      if (!customerFound) this.customerId = null;
    },
    tripName: async function () {
      await this.loadTrips();
      let tripFound = false;
      for (let trip of this.trips) {
        if (
          `${trip.name} (${trip.f_departure_date.substring(0, 10)})` ===
          this.tripName
        ) {
          this.tripId = trip.id;
          this.departurePlace = trip.departure_place;
          this.arrivalPlace = trip.arrival_place;
          await this.loadSpots();
          tripFound = true;
          break;
        }
      }
      if (!tripFound) this.tripId = null;
    },
    adultSpots: function () {
      if (this.spotIDsChosen.length > this.underageSpots + this.adultSpots)
        this.spotIDsChosen.pop();
    },
    underageSpots: function () {
      if (this.spotIDsChosen.length > this.underageSpots + this.adultSpots)
        this.spotIDsChosen.pop();
    },
  },
  created() {
    if (this.$route.query.customer_name)
      this.customerName = this.$route.query.customer_name;
    if (this.$route.query.trip_name)
      this.tripName = this.$route.query.trip_name;
  },
  methods: {
    loadCustomers: async function () {
      try {
        this.loading.push(true);
        let customer_name = this.customerName.replace(/ \(.*/, "").trim();
        let response = await index_customers(customer_name, 3, 1);
        this.customers = response.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    loadTrips: async function () {
      try {
        this.loading.push(true);
        let trip_name = this.tripName.replace(/ \(.*/, "").trim();
        let response = await index_trips(trip_name, 3, 1);
        this.trips = response.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    loadSpots: async function () {
      try {
        let response = await index_spots(this.tripId);
        this.spots = response.data.data;
      } catch (err) {
        this.$swal.fire({
          title: "No pudimos cargar la información",
          text: "Ha ocurrido un error inesperado al intentar procesar la solicitud",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
    selectSpot: function (is_available, spot_id) {
      if (this.spotIDsChosen.includes(spot_id)) {
        let index = this.spotIDsChosen.indexOf(spot_id);
        this.spotIDsChosen.splice(index, 1);
      } else if (
        is_available &&
        this.spotIDsChosen.length < this.underageSpots + this.adultSpots
      )
        this.spotIDsChosen.push(spot_id);
    },
    create: async function () {
      let params = this.getCreateParams();
      if (params === false) return;
      this.submitDisabled = true;
      this.btnValue = "Guardando";
      this.loading.push(true);
      try {
        await create(params);
        this.$router.push({ path: "/reservations" });
      } catch (err) {
        this.errorCreate(err);
      } finally {
        this.submitDisabled = false;
        this.btnValue = "Guardar";
        this.loading.pop();
      }
    },
    errorCreate: function (err) {
      console.log(err);
      this.$swal.fire({
        title: "La acción no pudo ser completada",
        text: `Ha ocurrido un error inesperado al intentar procesar la solicitud`,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    },
    getCreateParams: function () {
      if (
        this.customerName === "" ||
        this.tripName === "" ||
        this.adultSpots === "" ||
        this.underageSpots === ""
      ) {
        this.$swal.fire({
          title: `Complete todos los campos para continuar`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (this.customerId === null) {
        this.$swal.fire({
          title: `El cliente ${this.customerName} no fue encontrado en el sistema`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (this.tripId === null) {
        this.$swal.fire({
          title: `El viaje ${this.tripName} no fue encontrado en el sistema`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (this.spotIDsChosen.length === 0) {
        this.$swal.fire({
          title: "No ha seleccionado lugares para reservar",
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      return {
        id_customer: this.customerId,
        id_trip: this.tripId,
        reservation_means: this.reservationMeans,
        adult_spots: this.adultSpots,
        underage_spots: this.underageSpots,
        departure_place: this.departurePlace,
        arrival_place: this.arrivalPlace,
        discount_percentage: this.discountPercentage,
        spot_ids: this.spotIDsChosen,
      };
    },
  },
  components: {
    Nav,
    TitleSection,
    NumberSelectorComponent,
  },
};
</script>

<style lang="scss" scoped>
#new-reservation-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}

form {
  min-height: calc(100vh - 3em - 2em - 0.8em - 2em);
  position: relative;
  color: var(--light);
}
</style>
