import { createRouter, createWebHashHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import MyAccountView from "../views/MyAccountView.vue";
import TripsView from "../views/trips/TripsView.vue";
import TripView from "../views/trips/TripView.vue";
import NewTripView from "../views/trips/NewTripView.vue";
import TripBillsView from "../views/accounting/TripBillsView.vue";
import NewTripBillView from "../views/accounting/NewTripBillView.vue";
import EditTripBillView from "../views/accounting/EditTripBillView.vue";
import EmployeesView from "../views/employees/EmployeesView.vue";
import NewEmployeeView from "../views/employees/NewEmployeeView.vue";
import EditEmployeeView from "../views/employees/EditEmployeeView.vue";
import CustomersView from "../views/customers/CustomersView.vue";
import NewCustomerView from "../views/customers/NewCustomerView.vue";
import EditCustomerView from "../views/customers/EditCustomerView.vue";
import ReservationsView from "../views/reservations/ReservationsView.vue";
import ReservationView from "../views/reservations/ReservationView.vue";
import NewReservationView from "../views/reservations/NewReservationView.vue";
import ReservationPaymentsView from "../views/reservations/payments/ReservationPaymentsView.vue";
import NewReservationPaymentView from "../views/reservations/payments/NewReservationPaymentView.vue";
import EditReservationPaymentView from "../views/reservations/payments/EditReservationPaymentView.vue";
import AccountingView from "../views/accounting/AccountingView.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/my-account",
    name: "my-account",
    component: MyAccountView,
  },
  {
    path: "/trips",
    name: "trips",
    component: TripsView,
  },
  {
    path: "/trips/:id",
    name: "show-trip",
    component: TripView,
  },
  {
    path: "/trips/new",
    name: "new-trip",
    component: NewTripView,
  },
  {
    path: "/trips/:id/bills",
    name: "trip-bills",
    component: TripBillsView,
  },
  {
    path: "/trips/:id/bill",
    name: "new-trip-bill",
    component: NewTripBillView,
  },
  {
    path: "/trips/:id/bill/:idBill/edit",
    name: "edit-trip-bill",
    component: EditTripBillView,
  },
  {
    path: "/employees",
    name: "employees",
    component: EmployeesView,
  },
  {
    path: "/employees/new",
    name: "new-employee",
    component: NewEmployeeView,
  },
  {
    path: "/employees/:id/edit",
    name: "edit-employee",
    component: EditEmployeeView,
  },
  {
    path: "/customers",
    name: "customers",
    component: CustomersView,
  },
  {
    path: "/customers/new",
    name: "new-customer",
    component: NewCustomerView,
  },
  {
    path: "/customers/:id/edit",
    name: "edit-customer",
    component: EditCustomerView,
  },
  {
    path: "/reservations",
    name: "reservations",
    component: ReservationsView,
  },
  {
    path: "/reservations/:id",
    name: "show-reservation",
    component: ReservationView,
  },
  {
    path: "/reservations/new",
    name: "new-reservation",
    component: NewReservationView,
  },
  {
    path: "/reservations/:id/payments",
    name: "reservation-payments",
    component: ReservationPaymentsView,
  },
  {
    path: "/reservations/:id/payments/new",
    name: "new-reservation-payment",
    component: NewReservationPaymentView,
  },
  {
    path: "/reservations/:id/payments/:idPayment/edit",
    name: "edit-reservation-payment",
    component: EditReservationPaymentView,
  },
  {
    path: "/accounting",
    name: "accounting",
    component: AccountingView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to) => {
  if (!localStorage.user && to.name !== "login") return { name: "login" };
});

export default router;
