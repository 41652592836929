<template>
  <div id="my-account-container">
    <div id="main-container">
      <Nav></Nav>
      <section class="main-section">
        <TitleSection title="Mi cuenta" :loading="loading"></TitleSection>
        <form class="col-between" @submit.prevent="update">
          <div>
            <div class="form-control">
              <label class="form-label" for="first-name">Nombres</label>
              <input
                class="center input input-text"
                type="text"
                placeholder="Nombres"
                v-model="firstName"
              />
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Apellidos</label>
              <input
                class="center input input-text"
                type="text"
                placeholder="Apellidos"
                v-model="lastName"
              />
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Correo</label>
              <input
                class="center input input-text"
                type="email"
                placeholder="Correo"
                v-model="email"
              />
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Teléfono</label>
              <input
                class="center input input-text w10em"
                type="text"
                placeholder="Teléfono"
                v-model="phone"
              />
            </div>
            <div class="form-control">
              <label class="form-label underline mb1em" for="first-name">
                Opciones
              </label>
              <input
                class="center input btn w15em"
                type="button"
                value="Cambiar contraseña"
                @click="changePasswordWarning"
              />
            </div>
          </div>
          <div>
            <input
              class="input btn btn-submit"
              type="submit"
              :value="btnValue"
              :disabled="submitDisabled"
            />
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../components/Nav.vue";
import TitleSection from "../components/TitleSection.vue";

//Services
import { show, edit, change_password } from "@/services/users";

export default {
  name: "MyAccountView",
  data: function () {
    return {
      user_id: JSON.parse(localStorage.user).id,
      loading: [],
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      userTypeId: null,
      btnValue: "Guardar",
      submitDisabled: false,
    };
  },
  created() {
    this.loadUser();
  },
  methods: {
    loadUser: async function () {
      this.submitDisabled = true;
      this.loading.push(true);
      try {
        let response = await show(this.user_id);
        let res = response.data.data;
        this.firstName = res.first_name;
        this.lastName = res.last_name;
        this.email = res.email;
        this.phone = res.phone;
        this.userTypeId = res.user_type_id;
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
        this.loading.pop();
      }
    },
    changePasswordWarning: async function () {
      let response = await this.$swal.fire({
        title: "Introduce la nueva contraseña",
        text: "La contraseña debe tener entre 8 y 20 caracteres. Contener letras mayúsculas, minúsculas y al menos un número.",
        input: "password",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (response.isConfirmed) this.changePassword(response);
    },
    changePassword: async function (res) {
      if (!(await this.validatePassword(res.value)))
        return this.changePasswordWarning();
      this.loading.push(true);
      try {
        await change_password(this.user_id, { password: res.value });
        this.successChangePassword();
      } catch (err) {
        this.errorChangePassword(err);
      } finally {
        this.loading.pop();
      }
    },
    validatePassword: async function (password) {
      if (
        !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,20}$/.test(password)
      ) {
        await this.$swal.fire({
          title: "La contraseña no es segura",
          text: "La contraseña debe tener entre 8 y 20 caracteres. Contener letras mayúsculas, minúsculas y al menos un número.",
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      return true;
    },
    successChangePassword: function () {
      this.$swal.fire({
        title: "La contraseña ha sido cambiada con éxito",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    },
    errorChangePassword: function (err) {
      console.log(err);
      this.$swal.fire({
        title: "La acción no pudo ser completada",
        text: "Ha ocurrido un error inesperado al intentar procesar la solicitud",
        icon: "error",
      });
    },
    update: async function () {
      let params = this.getUpdateParams();
      if (params === false) return;
      this.loading.push(true);
      this.submitDisabled = true;
      this.btnValue = "Guardando";
      try {
        await edit(this.user_id, params);
        this.successUpdate();
      } catch (err) {
        this.errorUpdate(err);
      } finally {
        this.submitDisabled = false;
        this.btnValue = "Guardar";
        this.loading.pop();
      }
    },
    getUpdateParams: function () {
      if (
        this.firstName == "" ||
        this.lastName == "" ||
        this.email == "" ||
        this.phone == ""
      ) {
        this.$swal.fire({
          title: `Complete todos los campos para continuar`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      let params = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        user_type_id: this.userTypeId,
      };
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/i.test(params.first_name)) {
        this.$swal.fire({
          title: `El nombre "${params.first_name}" es inválido`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/i.test(params.last_name)) {
        this.$swal.fire({
          title: `El apellido "${params.last_name}" es inválido`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(params.email)) {
        this.$swal.fire({
          title: `El correo "${params.email}" es inválido`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (
        !/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
          params.phone
        )
      ) {
        this.$swal.fire({
          title: `El teléfono "${params.phone}" es inválido`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      return params;
    },
    successUpdate: function () {
      this.$swal.fire({
        title: "Los datos han sido actualizados con éxito",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    },
    errorUpdate: function (err) {
      console.log(err);
      this.$swal.fire({
        title: "La acción no pudo ser completada",
        text: "Ha ocurrido un error inesperado al intentar procesar la solicitud",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    },
  },
  components: {
    Nav,
    TitleSection,
  },
};
</script>

<style lang="scss" scoped>
#my-account-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}

form {
  min-height: calc(100vh - 3em - 2em - 0.8em - 2em);
  position: relative;
  color: var(--light);
}
</style>
