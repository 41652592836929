import { API, getAuthorization } from "./axiosConfig";

const signIn = (params) => {
  return API.post("users/signin", params);
};

const index = (search_by, per_page, page) => {
  API.defaults.headers.Authorization = getAuthorization();
  const config = {
    params: {
      search_by: search_by,
      per_page: per_page,
      page: page,
    },
  };
  return API.get("users", config);
};

const show = (id_user) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.get(`users/${id_user}`);
};

const total_items = (search_by) => {
  API.defaults.headers.Authorization = getAuthorization();
  const config = {
    params: {
      search_by: search_by,
    },
  };
  return API.get("users/total-items", config);
};

const create = (params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.post("users", params);
};

const edit = (id_user, params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.put(`users/${id_user}`, params);
};

const new_password = (id_user) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.patch(`users/${id_user}/new-password`, null);
};

const change_password = (id_user, params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.patch(`users/${id_user}/password`, params);
};

const remove = (id_user) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.delete(`users/${id_user}`);
};

export {
  signIn,
  index,
  show,
  total_items,
  create,
  edit,
  new_password,
  change_password,
  remove,
};
