<template>
  <div id="trip-bills-container">
    <div id="main-container">
      <Nav navOptionChosen="accounting"></Nav>
      <section class="main-section">
        <div>
          <TitleSection title="Gastos" :loading="loading"></TitleSection>
          <section class="h1_8em mb1em row-between">
            <p class="top-left-text">Gastos totales: ${{ totalExpenses }}</p>
            <router-link
              class="h100 center"
              :to="{
                name: 'new-trip-bill',
                params: { id: this.$route.params.id },
              }"
            >
              <img class="h100" src="@/assets/img/new.svg" />
            </router-link>
          </section>
          <section class="vertical-info">
            <div class="vertical-info-box" v-for="bill in bills" :key="bill.id">
              <p class="top-left">{{ bill.created_at.substring(0, 10) }}</p>
              <p class="bottom-left">${{ bill.amount }} - {{ bill.name }}</p>
              <div
                class="top-right dots"
                @click="showOption(bill.id)"
                @focusout="showOption(null)"
                tabindex="-1"
              >
                <div class="optionDot"></div>
                <div class="optionDot"></div>
                <div class="optionDot"></div>
                <div
                  class="top-right recordOptions"
                  v-if="optionsActive === bill.id"
                >
                  <div
                    class="recordOption"
                    @click="
                      this.$router.push({
                        name: 'edit-trip-bill',
                        params: { id: this.$route.params.id, idBill: bill.id },
                      })
                    "
                  >
                    Editar
                  </div>
                  <div
                    class="recordOption"
                    @click="removeWarning(bill.id, bill.name)"
                  >
                    Eliminar
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";

// Services
import { index, remove } from "@/services/tripBills";

export default {
  name: "TripBillsView",
  data: function () {
    return {
      loading: [],
      totalExpenses: 0,
      bills: [],
      optionsActive: null,
    };
  },
  created() {
    this.loadBills();
  },
  methods: {
    loadBills: async function () {
      this.loading.push(true);
      this.bills = [];
      this.totalExpenses = 0;
      try {
        let response = await index(this.$route.params.id);
        this.bills = response.data.data;
        for (let bill of this.bills) this.totalExpenses += bill.amount;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    showOption: function (id) {
      if (this.optionsActive != id) this.optionsActive = id;
      else this.optionsActive = null;
    },
    removeWarning: async function (idBill, billName) {
      const res = await this.$swal.fire({
        title: `¿Está seguro de eliminar el gasto ${billName}?`,
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (res.isConfirmed) this.removeBill(idBill);
    },
    removeBill: function (idBill) {
      remove(this.$route.params.id, idBill)
        .then(() => {
          this.loadBills();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    Nav,
    TitleSection,
  },
};
</script>

<style lang="scss" scoped>
#trip-bills-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}
</style>
