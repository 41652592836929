<template>
  <div id="reservations-container">
    <div id="main-container">
      <Nav navOptionChosen="reservations"></Nav>
      <section class="main-section col-between">
        <div>
          <TitleSection title="Reservaciones" :loading="loading"></TitleSection>
          <section class="h1_8em row-between">
            <div class="search-container">
              <input
                class="input input-text input-search"
                type="text"
                placeholder="Buscar"
                v-model="search_by"
              />
            </div>
            <router-link class="h100 center" to="/reservations/new">
              <img class="h100" src="@/assets/img/new.svg" />
            </router-link>
          </section>
          <table class="table-data">
            <thead>
              <tr>
                <th>Id cliente</th>
                <th>Id viaje</th>
                <th>Cliente</th>
                <th>Viaje</th>
                <th>Espacios</th>
                <th>Total</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'bg-wrong': reservation.canceled_at }"
                v-for="reservation in reservations"
                :key="reservation.id"
              >
                <td>{{ reservation.client_number }}</td>
                <td>{{ reservation.trip_number }}</td>
                <td>{{ reservation.full_name }}</td>
                <td>{{ reservation.trip_name }}</td>
                <td>{{ reservation.spots }}</td>
                <td>${{ reservation.total }}</td>
                <td>{{ reservation.state }}</td>
                <td
                  class="dots"
                  @click="showOption(reservation.id)"
                  @focusout="showOption(null)"
                  tabindex="-1"
                >
                  <div class="col-center">
                    <div class="optionDot"></div>
                    <div class="optionDot"></div>
                    <div class="optionDot"></div>
                  </div>
                  <div
                    v-if="optionsActive === reservation.id"
                    class="recordOptions"
                  >
                    <div
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'show-reservation',
                          params: { id: reservation.id },
                        })
                      "
                    >
                      Detalles
                    </div>
                    <div
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'reservation-payments',
                          params: { id: reservation.id },
                        })
                      "
                    >
                      Ver pagos
                    </div>
                    <div
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'new-reservation-payment',
                          params: { id: reservation.id },
                        })
                      "
                    >
                      Nuevo pago
                    </div>
                    <div
                      class="recordOption"
                      @click="
                        cancelWarning(reservation.id, reservation.full_name)
                      "
                    >
                      Cancelar
                    </div>
                    <div
                      class="recordOption"
                      @click="
                        removeWarning(reservation.id, reservation.full_name)
                      "
                    >
                      Eliminar
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :per_page="per_page"
          :total_items="total_items"
          :search_by="search_by"
          @new-page="loadTable"
        >
        </Pagination>
      </section>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";
import Pagination from "../../components/Pagination.vue";

// Services
import { index, total_items, cancel, remove } from "@/services/reservations";

export default {
  name: "ReservationsView",
  data: function () {
    return {
      loading: [],
      search_by: null,
      reservations: [],
      per_page: 7,
      total_items: null,
      optionsActive: null,
    };
  },
  watch: {
    search_by: function () {
      this.debouncedLoadTable();
    },
  },
  created() {
    this.setPerPage();
    this.search_by = this.$route.query.search_by;
    this.debouncedLoadTable = _.debounce(() => {
      this.loadTable(1);
    }, 500);
  },
  methods: {
    loadTable: async function (page) {
      this.reservations = [];
      this.loading.push(true);
      try {
        let response = await index(this.search_by, this.per_page, page);
        this.reservations = response.data.data;
        response = await total_items(this.search_by);
        this.total_items = response.data.data.total_items;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    showOption: function (id) {
      if (this.optionsActive != id) this.optionsActive = id;
      else this.optionsActive = null;
    },
    cancelWarning: async function (id_reservation, customer_name) {
      const res = await this.$swal.fire({
        title: `¿Está seguro de cancelar la reservación de ${customer_name}?`,
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (res.isConfirmed) this.cancelReservation(id_reservation);
    },
    removeWarning: async function (id_reservation, customer_name) {
      const res = await this.$swal.fire({
        title: `¿Está seguro de eliminar la reservación de ${customer_name}?`,
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (res.isConfirmed) this.deleteReservation(id_reservation);
    },
    cancelReservation: function (id_reservation) {
      cancel(id_reservation)
        .then(() => {
          this.loadTable(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteReservation: function (id_reservation) {
      remove(id_reservation)
        .then(() => {
          this.loadTable(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setPerPage: function () {
      if (window.innerHeight <= 450) this.per_page = 5;
      else if (window.innerHeight <= 600) this.per_page = 6;
      else if (window.innerHeight <= 750) this.per_page = 7;
    },
  },
  components: {
    Nav,
    TitleSection,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
#reservations-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}
</style>
