<template>
  <div id="show-reservation-container">
    <div id="main-container">
      <Nav navOptionChosen="reservations"></Nav>
      <section class="main-section">
        <TitleSection title="Detalles de la reservación" :loading="loading">
        </TitleSection>
        <MadeBy
          :created_by="reservation.created_by_name"
          :created_at="reservation.created_at"
        ></MadeBy>
        <div id="info-container">
          <section class="info-section">
            <div class="info-group">
              <h4 class="subtitle">Cliente</h4>
              <p class="p-info">{{ reservation.customer_name }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Viaje</h4>
              <p class="p-info">{{ reservation.trip_name }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Medio de reservación</h4>
              <p class="p-info">{{ reservation.reservation_means }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Espacios</h4>
              <p class="p-info">
                {{ reservation.adult_spots }} adultos y
                {{ reservation.underage_spots }} menores
              </p>
              <div class="spots-container">
                <div
                  class="spot-number center"
                  :class="[
                    spot.belongs_to_reservation ? 'busy-spot' : 'free-spot',
                  ]"
                  v-for="spot in spots"
                  :key="spot.id"
                >
                  {{ spot.spot_number }}
                </div>
              </div>
            </div>
          </section>
          <section class="info-section">
            <div class="info-group">
              <h4 class="subtitle">Estado</h4>
              <p class="p-info">
                {{ reservation.state }}
              </p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Total</h4>
              <p class="p-discount">
                <del>${{ reservation.total_before_discount }}</del>
                {{ `-${reservation.discount_percentage}% OFF` }}
              </p>
              <p class="p-info">${{ reservation.total }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Abonado</h4>
              <p class="p-info">${{ reservation.paid }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Lugar de partida</h4>
              <p class="p-info">{{ reservation.departure_place }}</p>
            </div>
            <div class="info-group">
              <h4 class="subtitle">Lugar de llegada</h4>
              <p class="p-info">{{ reservation.arrival_place }}</p>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";
import MadeBy from "../../components/MadeBy.vue";

// Services
import { show, index_spots } from "@/services/reservations";

export default {
  name: "ReservationView",
  data: function () {
    return {
      loading: [],
      reservation: {},
      spots: [],
    };
  },
  created() {
    this.loadReservation();
    this.loadSpots();
  },
  methods: {
    loadReservation: async function () {
      try {
        let response = await show(this.$route.params.id);
        this.reservation = response.data.data;
      } catch (err) {
        this.$swal.fire({
          title: "No pudimos cargar la información",
          text: "Ha ocurrido un error inesperado al intentar procesar la solicitud",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
    loadSpots: async function () {
      try {
        let response = await index_spots(this.$route.params.id);
        this.spots = response.data.data;
      } catch (err) {
        this.$swal.fire({
          title: "No pudimos cargar la información",
          text: "Ha ocurrido un error inesperado al intentar procesar la solicitud",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
  },
  components: {
    Nav,
    TitleSection,
    MadeBy,
  },
};
</script>

<style lang="scss" scoped>
#show-reservation-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}

#info-container {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 2em;
  color: var(--light);
}

.info-section {
  width: 50%;
}

.info-group {
  width: 100%;
  height: auto;
  margin-bottom: 2em;
}

.subtitle {
  font-size: 0.7em;
  font-weight: bold;
  margin-bottom: 0.7em;
}

.p-info {
  font-size: 0.9em;
}

.p-discount {
  font-size: 0.7em;
  color: var(--txt-discount);
}
</style>
