<template>
  <div id="customers-container">
    <div id="main-container">
      <Nav navOptionChosen="customers"></Nav>
      <section class="main-section col-between">
        <div>
          <TitleSection title="Clientes" :loading="loading"></TitleSection>
          <section class="h1_8em row-between">
            <div class="search-container">
              <input
                class="input input-text input-search"
                type="text"
                placeholder="Buscar"
                v-model="search_by"
              />
            </div>
            <router-link class="h100 center" to="/customers/new">
              <img class="h100" src="@/assets/img/new.svg" />
            </router-link>
          </section>
          <table class="table-data">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Teléfono</th>
                <th>Adeuda</th>
                <th>Reservaciones</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="customer in customers" :key="customer.id">
                <td>{{ customer.client_number }}</td>
                <td>{{ customer.full_name }}</td>
                <td>{{ customer.phone }}</td>
                <td>${{ customer.debit }}</td>
                <td>{{ customer.total_reservations }}</td>
                <td
                  class="dots"
                  @click="showOption(customer.id)"
                  @focusout="showOption(null)"
                  tabindex="-1"
                >
                  <div class="col-center">
                    <div class="optionDot"></div>
                    <div class="optionDot"></div>
                    <div class="optionDot"></div>
                  </div>
                  <div
                    v-if="optionsActive === customer.id"
                    class="recordOptions"
                  >
                    <div
                      v-if="!customer.trip_canceled_at"
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'reservations',
                          query: { search_by: customer.client_number },
                        })
                      "
                    >
                      Ver reservaciones
                    </div>
                    <div
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'new-reservation',
                          query: {
                            customer_name: `${customer.full_name} (${customer.phone})`,
                          },
                        })
                      "
                    >
                      Nueva reservación
                    </div>
                    <div
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'edit-customer',
                          params: { id: customer.id },
                        })
                      "
                    >
                      Editar
                    </div>
                    <div
                      v-if="!customer.trip_canceled_at"
                      class="recordOption"
                      @click="removeWarning(customer.id, customer.full_name)"
                    >
                      Eliminar
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :per_page="per_page"
          :total_items="total_items"
          :search_by="search_by"
          @new-page="loadTable"
        >
        </Pagination>
      </section>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";
import Pagination from "../../components/Pagination.vue";

// Services
import { index, total_items, remove } from "@/services/customers";

export default {
  name: "CsutomersView",
  data: function () {
    return {
      loading: [],
      search_by: null,
      customers: [],
      per_page: 7,
      total_items: null,
      optionsActive: null,
    };
  },
  watch: {
    search_by: function () {
      this.debouncedLoadTable();
    },
  },
  created() {
    this.setPerPage();
    this.loadTable(1);
    this.debouncedLoadTable = _.debounce(() => {
      this.loadTable(1);
    }, 500);
  },
  methods: {
    loadTable: async function (page) {
      this.customers = [];
      this.loading.push(true);
      try {
        let response = await index(this.search_by, this.per_page, page);
        this.customers = response.data.data;
        response = await total_items(this.search_by);
        this.total_items = response.data.data.total_items;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    showOption: function (id) {
      if (this.optionsActive != id) this.optionsActive = id;
      else this.optionsActive = null;
    },
    removeWarning: async function (id_customer, customer_name) {
      const res = await this.$swal.fire({
        title: `¿Está seguro de eliminar al cliente ${customer_name}?`,
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (res.isConfirmed) this.removeCustomer(id_customer);
    },
    removeCustomer: function (id_customer) {
      remove(id_customer)
        .then(() => {
          this.loadTable(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setPerPage: function () {
      if (window.innerHeight <= 450) this.per_page = 5;
      else if (window.innerHeight <= 600) this.per_page = 6;
      else if (window.innerHeight <= 750) this.per_page = 7;
    },
  },
  components: {
    Nav,
    TitleSection,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
#customers-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  display: flex;
}
</style>
