<template>
  <div id="reservation-payments-container">
    <div id="main-container">
      <Nav navOptionChosen="reservations"></Nav>
      <section class="main-section">
        <div>
          <TitleSection title="Pagos de reservación" :loading="loading">
          </TitleSection>
          <section class="h1_8em mb1em row-between">
            <p class="top-left-text">
              Abonado ${{ reservation.paid }} de ${{ reservation.total }}
            </p>
            <router-link
              class="h100 center"
              :to="{
                name: 'new-reservation-payment',
                params: { id: this.$route.params.id },
              }"
            >
              <img class="h100" src="@/assets/img/new.svg" />
            </router-link>
          </section>
          <section class="vertical-info">
            <div
              class="vertical-info-box"
              v-for="payment in payments"
              :key="payment.id"
            >
              <p class="top-left">{{ payment.created_at.substring(0, 10) }}</p>
              <p class="bottom-left">
                ${{ payment.amount }} - {{ payment.payment_type }}
              </p>
              <div
                class="top-right dots"
                @click="showOption(payment.id)"
                @focusout="showOption(null)"
                tabindex="-1"
              >
                <div class="optionDot"></div>
                <div class="optionDot"></div>
                <div class="optionDot"></div>
                <div
                  class="top-right recordOptions"
                  v-if="optionsActive === payment.id"
                >
                  <div
                    class="recordOption"
                    @click="
                      this.$router.push({
                        name: 'edit-reservation-payment',
                        params: {
                          id: this.$route.params.id,
                          idPayment: payment.id,
                        },
                      })
                    "
                  >
                    Editar
                  </div>
                  <div
                    class="recordOption"
                    @click="removeWarning(payment.id, payment.amount)"
                  >
                    Eliminar
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../../components/Nav.vue";
import TitleSection from "../../../components/TitleSection.vue";

// Services
import { index, remove } from "@/services/reservationPayments";
import { show } from "@/services/reservations";

export default {
  name: "ReservationPaymentsView",
  data: function () {
    return {
      loading: [],
      totalPaid: 0,
      reservation: { paid: 0, total: 0 },
      payments: [],
      optionsActive: null,
    };
  },
  created() {
    this.loadReservation();
    this.loadPayments();
  },
  methods: {
    loadReservation: async function () {
      this.loading.push(true);
      try {
        let response = await show(this.$route.params.id);
        this.reservation = response.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    loadPayments: async function () {
      this.loading.push(true);
      this.payments = [];
      this.totalPaid = 0;
      try {
        let response = await index(this.$route.params.id);
        this.payments = response.data.data;
        for (let payment of this.payments)
          switch (payment.payment_type) {
            case "CASH":
              payment.payment_type = "Efectivo";
              break;
            case "CASH_DEPOSIT":
              payment.payment_type = "Depósito";
              break;
            default:
              payment.payment_type = "Otro";
              break;
          }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    showOption: function (id) {
      if (this.optionsActive != id) this.optionsActive = id;
      else this.optionsActive = null;
    },
    removeWarning: async function (idPayment, paymentAmount) {
      const res = await this.$swal.fire({
        title: `¿Está seguro de eliminar el abono de $${paymentAmount}?`,
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (res.isConfirmed) this.removePayment(idPayment);
    },
    removePayment: function (idPayment) {
      remove(this.$route.params.id, idPayment)
        .then(() => {
          this.loadReservation();
          this.loadPayments();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    Nav,
    TitleSection,
  },
};
</script>

<style lang="scss" scoped>
#reservation-payments-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}
</style>
