<template>
  <div id="made-by-section-container">
    <div class="tag">
      Creado por {{ created_by }} el
      {{ created_at ? created_at.substring(0, 10) : "" }}
      a las {{ created_at ? created_at.substring(11, 16) : "" }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MadeByComponent",
  props: { created_by: String, created_at: String },
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#made-by-section-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.tag {
  font-size: 0.7em;
  color: var(--light);
}
</style>
