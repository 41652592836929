<template>
  <div id="number-selector-section-container">
    <div id="main-container">
      <div class="center btnArrow">
        <img
          id="triangleLeft"
          src="@/assets/img/triangleLeft.svg"
          @click="number > minValue ? $emit('add-number', -1) : number"
        />
      </div>
      <input
        class="center input input-number w10em"
        type="number"
        :value="number"
      />
      <div class="center btnArrow">
        <img
          id="triangleRight"
          src="@/assets/img/triangleRight.svg"
          @click="$emit('add-number', 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberSelectorComponent",
  props: { number: Number, minValue: Number },
};
</script>

<style lang="scss" scoped>
#number-selector-section-container {
  width: 100%;
  height: auto;
}

#main-container {
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.btnArrow {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

input {
  width: 4em;
  margin: 0 0.5em;
  text-align: center;
}

img {
  width: 100%;
  height: 100%;
}
</style>
