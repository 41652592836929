<template>
  <div id="new-trip-container">
    <div id="main-container">
      <Nav navOptionChosen="trips"></Nav>
      <section class="main-section col-between">
        <TitleSection title="Nuevo viaje" :loading="loading"></TitleSection>
        <form @submit.prevent="create">
          <div>
            <div class="form-control">
              <label class="form-label" for="first-name">Nombre</label>
              <input
                class="center input input-text"
                type="text"
                placeholder="Nombre"
                v-model="name"
              />
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Descripción</label>
              <textarea
                class="w100 textarea"
                maxlength="1000"
                placeholder="Descripción sobre el viaje"
                v-model="description"
              >
              </textarea>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Lugares</label>
              <NumberSelectorComponent
                :number="spots"
                :minValue="0"
                @add-number="(num) => (spots += num)"
              >
              </NumberSelectorComponent>
            </div>
            <div class="form-control">
              <label class="form-label" for="adult-spot-price">
                Precio de adulto
              </label>
              <div>
                <input
                  id="adult-spot-price"
                  class="center input input-number w10em"
                  type="number"
                  step="0.01"
                  v-model="adultSpotPrice"
                />
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="underage-spot-price">
                Precio de menor de edad
              </label>
              <div>
                <input
                  id="underage-spot-price"
                  class="center input input-number w10em"
                  type="number"
                  step="0.01"
                  v-model="underageSpotPrice"
                />
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Fecha de salida</label>
              <div>
                <input
                  class="center input input-datetime"
                  type="datetime-local"
                  v-model="departureDate"
                />
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">
                Fecha de llegada
              </label>
              <div>
                <input
                  class="center input input-datetime"
                  type="datetime-local"
                  v-model="arrivalDate"
                />
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">
                Lugar de partida
              </label>
              <div>
                <input
                  class="center input input-text"
                  type="text"
                  v-model="departurePlace"
                />
              </div>
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">
                Lugar de llegada
              </label>
              <div>
                <input
                  class="center input input-text"
                  type="text"
                  v-model="arrivalPlace"
                />
              </div>
            </div>
          </div>
          <input
            class="input btn btn-submit"
            type="submit"
            :value="btnValue"
            :disabled="submitDisabled"
          />
        </form>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";
import NumberSelectorComponent from "../../components/NumberSelector.vue";

// Services
import { create } from "@/services/trips";

export default {
  name: "NewTripView",
  data: function () {
    return {
      loading: [],
      name: "",
      description: "",
      spots: 46,
      adultSpotPrice: 200,
      underageSpotPrice: 100,
      departureDate: new Date().toISOString().substring(0, 16),
      arrivalDate: new Date().toISOString().substring(0, 16),
      departurePlace: "",
      arrivalPlace: "",
      btnValue: "Guardar",
      submitDisabled: false,
    };
  },
  methods: {
    create: async function () {
      let params = this.getCreateParams();
      if (params === false) return;
      this.submitDisabled = true;
      this.btnValue = "Guardando";
      this.loading.push(true);
      try {
        await create(params);
        this.$router.push({ path: "/trips" });
      } catch (err) {
        this.errorCreate(err);
      } finally {
        this.submitDisabled = false;
        this.btnValue = "Guardar";
        this.loading.pop();
      }
    },
    errorCreate: function (err) {
      console.log(err);
      this.$swal.fire({
        title: "La acción no pudo ser completada",
        text: `Ha ocurrido un error inesperado al intentar procesar la solicitud`,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    },
    getCreateParams: function () {
      if (
        this.name == "" ||
        this.description == "" ||
        this.spots == "" ||
        this.adultSpotPrice == "" ||
        this.underageSpotPrice == "" ||
        this.departureDate == "" ||
        this.arrivalDate == "" ||
        this.departurePlace == "" ||
        this.arrivalPlace == ""
      ) {
        this.$swal.fire({
          title: `Complete todos los campos para continuar`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      return {
        name: this.name,
        description: this.description,
        spots: this.spots,
        adult_spot_price: parseFloat(this.adultSpotPrice),
        underage_spot_price: parseFloat(this.underageSpotPrice),
        departure_date: this.departureDate,
        arrival_date: this.arrivalDate,
        departure_place: this.departurePlace,
        arrival_place: this.arrivalPlace,
      };
    },
  },
  components: {
    Nav,
    TitleSection,
    NumberSelectorComponent,
  },
};
</script>

<style lang="scss" scoped>
#new-trip-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}

form {
  min-height: calc(100vh - 3em - 2em - 0.8em - 2em);
  position: relative;
  color: var(--light);
}
</style>
