<template>
  <div id="trips-container">
    <div id="main-container">
      <Nav navOptionChosen="trips"></Nav>
      <section class="main-section col-between">
        <div>
          <TitleSection title="Viajes" :loading="loading"></TitleSection>
          <section class="h1_8em row-between">
            <div class="search-container">
              <input
                class="input input-text input-search"
                type="text"
                placeholder="Buscar"
                v-model="search_by"
              />
            </div>
            <router-link class="h100 center" to="/trips/new">
              <img class="h100" src="@/assets/img/new.svg" />
            </router-link>
          </section>
          <table class="table-data">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Espacios</th>
                <th>Salida</th>
                <th>Llegada</th>
                <th>Adulto</th>
                <th>Menor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'bg-wrong': trip.trip_canceled_at }"
                v-for="trip in trips"
                :key="trip.id"
              >
                <td>{{ trip.trip_number }}</td>
                <td>{{ trip.name }}</td>
                <td>{{ trip.free_spots }}</td>
                <td>{{ trip.f_departure_date.substring(0, 16) }}</td>
                <td>{{ trip.f_arrival_date.substring(0, 16) }}</td>
                <td>${{ trip.adult_spot_price }}</td>
                <td>${{ trip.underage_spot_price }}</td>
                <td
                  class="dots"
                  @click="showOption(trip.id)"
                  @focusout="showOption(null)"
                  tabindex="-1"
                >
                  <div class="col-center">
                    <div class="optionDot"></div>
                    <div class="optionDot"></div>
                    <div class="optionDot"></div>
                  </div>
                  <div v-if="optionsActive === trip.id" class="recordOptions">
                    <div
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'show-trip',
                          params: { id: trip.id },
                        })
                      "
                    >
                      Detalles
                    </div>
                    <div
                      v-if="!trip.trip_canceled_at"
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'new-reservation',
                          query: {
                            trip_name: `${
                              trip.name
                            } (${trip.f_departure_date.substring(0, 10)})`,
                          },
                        })
                      "
                    >
                      Nueva reservación
                    </div>
                    <div
                      class="recordOption"
                      @click="
                        this.$router.push({
                          name: 'reservations',
                          query: { search_by: trip.trip_number },
                        })
                      "
                    >
                      Ver reservaciones
                    </div>
                    <div
                      v-if="!trip.trip_canceled_at"
                      class="recordOption"
                      @click="cancelWarning(trip.id, trip.name)"
                    >
                      Cancelar viaje
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :per_page="per_page"
          :total_items="total_items"
          :search_by="search_by"
          @new-page="loadTable"
        >
        </Pagination>
      </section>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";
import Pagination from "../../components/Pagination.vue";

// Services
import { index, total_items, cancel } from "@/services/trips";

export default {
  name: "TripsView",
  data: function () {
    return {
      loading: [],
      search_by: null,
      trips: [],
      per_page: 7,
      total_items: null,
      optionsActive: null,
    };
  },
  watch: {
    search_by: function () {
      this.debouncedLoadTable();
    },
  },
  created() {
    this.setPerPage();
    this.loadTable(1);
    this.debouncedLoadTable = _.debounce(() => {
      this.loadTable(1);
    }, 500);
  },
  methods: {
    loadTable: async function (page) {
      this.trips = [];
      this.loading.push(true);
      try {
        let response = await index(this.search_by, this.per_page, page);
        this.trips = response.data.data;
        response = await total_items(this.search_by);
        this.total_items = response.data.data.total_items;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    cancelWarning: async function (id_trip, trip_name) {
      const res = await this.$swal.fire({
        title: `¿Está seguro de cancelar el viaje ${trip_name}?`,
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Volver",
        reverseButtons: true,
      });
      if (res.isConfirmed) this.cancelTrip(id_trip);
    },
    cancelTrip: async function (id_trip) {
      try {
        await cancel(id_trip);
        this.loadTable(1);
      } catch (err) {
        console.log(err);
      }
    },
    showOption: function (id) {
      if (this.optionsActive != id) this.optionsActive = id;
      else this.optionsActive = null;
    },
    setPerPage: function () {
      if (window.innerHeight <= 450) this.per_page = 4;
      else if (window.innerHeight <= 600) this.per_page = 5;
      else if (window.innerHeight <= 750) this.per_page = 6;
    },
  },
  components: {
    Nav,
    TitleSection,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
#trips-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  display: flex;
}
</style>
