import { API, getAuthorization } from "./axiosConfig";

const index = (id_reservation) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.get(`reservations/${id_reservation}/payments`);
};

const show = (id_reservation, id_payment) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.get(`reservations/${id_reservation}/payments/${id_payment}`);
};

const create = (id_reservation, params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.post(`reservations/${id_reservation}/payments`, params);
};

const edit = (id_reservation, id_payment, params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.put(
    `reservations/${id_reservation}/payments/${id_payment}`,
    params
  );
};

const remove = (id_reservation, id_payment) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.delete(`reservations/${id_reservation}/payments/${id_payment}`);
};

export { index, show, create, edit, remove };
