<template>
  <div id="edit-employee-container">
    <div id="main-container">
      <Nav navOptionChosen="employees"></Nav>
      <section class="main-section">
        <TitleSection title="Editar empleado" :loading="loading"></TitleSection>
        <form class="col-between" @submit.prevent="update">
          <div>
            <div class="form-control">
              <label class="form-label" for="first-name">Nombres</label>
              <input
                class="center input input-text"
                type="text"
                placeholder="Nombres"
                v-model="firstName"
              />
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Apellidos</label>
              <input
                class="center input input-text"
                type="text"
                placeholder="Apellidos"
                v-model="lastName"
              />
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Correo</label>
              <input
                class="center input input-text"
                type="email"
                placeholder="Correo"
                v-model="email"
              />
            </div>
            <div class="form-control">
              <label class="form-label" for="first-name">Teléfono</label>
              <input
                class="center input input-text w10em"
                type="text"
                placeholder="Teléfono"
                v-model="phone"
              />
            </div>
            <div class="form-control">
              <label class="form-label">Tipo</label>
              <div class="radios-group">
                <div
                  class="input-radio center"
                  :class="{ 'input-radio-checked': userTypeId === 1 }"
                  @click="userTypeId = 1"
                >
                  Administrador
                </div>
                <div
                  class="input-radio center"
                  :class="{ 'input-radio-checked': userTypeId === 2 }"
                  @click="userTypeId = 2"
                >
                  Empleado
                </div>
              </div>
            </div>
            <div class="form-control">
              <label class="form-label underline mb1em" for="first-name">
                Opciones
              </label>
              <input
                class="center input btn w10em"
                type="button"
                :value="btnNewPassValue"
                @click="newPasswordWarning"
              />
            </div>
          </div>
          <div>
            <input
              class="input btn btn-submit"
              type="submit"
              :value="btnValue"
              :disabled="submitDisabled"
            />
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";

//Services
import { show, edit, new_password } from "@/services/users";

export default {
  name: "EditEmployeeView",
  data: function () {
    return {
      edit_id: this.$route.params.id,
      loading: [],
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      userTypeId: null,
      btnNewPassValue: "Nueva contraseña",
      btnValue: "Guardar",
      submitDisabled: true,
    };
  },
  created() {
    this.loadUser();
  },
  methods: {
    loadUser: async function () {
      this.loading.push(true);
      try {
        const res = await show(this.edit_id);
        let response = res.data.data;
        this.firstName = response.first_name;
        this.lastName = response.last_name;
        this.email = response.email;
        this.phone = response.phone;
        this.userTypeId = response.user_type_id;
        this.submitDisabled = false;
      } catch (err) {
        console.log(err);
        this.$swal.fire({
          title: "La acción no pudo ser completada",
          text: err,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      } finally {
        this.loading.pop();
      }
    },
    newPasswordWarning: function () {
      this.$swal
        .fire({
          title: "¿Generar nueva contraseña? esta acción no se puede deshacer.",
          icon: "warning",
          confirmButtonText: "Aceptar",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        })
        .then((res) => this.newPassword(res));
    },
    newPassword: function (res) {
      if (!res.isConfirmed) return;
      this.loading.push(true);
      new_password(this.edit_id)
        .then((res) => res.data)
        .then((response) => this.successNewPassword(response))
        .catch((err) => this.errorNewPassword(err))
        .finally(() => this.loading.pop());
    },
    successNewPassword: function (response) {
      response = response.data;
      this.$swal
        .fire({
          title: "Nueva contraseña generada",
          html: `
            <p>Asegúrate de anotar esta contraseña ya que no podrás volver a verla:</p>
            <br>
            <h1>${response.password}</h1>
          `,
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        .then(() => this.$router.push({ path: "/employees" }));
    },
    errorNewPassword: function (err) {
      console.log(err);
      this.$swal.fire({
        title:
          "Ha ocurrido un error inesperado al intentar procesar la solicitud.",
        text: err,
        icon: "error",
      });
    },
    update: async function () {
      let params = this.getUpdateParams();
      if (params === false) return;
      this.submitDisabled = true;
      this.btnValue = "Guardando";
      this.loading.push(true);
      try {
        await edit(this.edit_id, params);
        this.$router.push({ name: "employees" });
      } catch (err) {
        this.errorUpdate(err);
      } finally {
        this.submitDisabled = false;
        this.btnValue = "Guardar";
        this.loading.pop();
      }
    },
    getUpdateParams: function () {
      if (
        this.userTypeId == null ||
        this.firstName == "" ||
        this.lastName == "" ||
        this.email == "" ||
        this.phone == ""
      ) {
        this.$swal.fire({
          title: `Complete todos los campos para continuar`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      let params = {
        user_type_id: this.userTypeId,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
      };
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/i.test(params.first_name)) {
        this.$swal.fire({
          title: `El nombre "${params.first_name}" es inválido`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/i.test(params.last_name)) {
        this.$swal.fire({
          title: `El apellido "${params.last_name}" es inválido`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(params.email)) {
        this.$swal.fire({
          title: `El correo "${params.email}" es inválido`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (
        !/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
          params.phone
        )
      ) {
        this.$swal.fire({
          title: `El teléfono "${params.phone}" es inválido`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      return params;
    },
    errorUpdate: function (err) {
      console.log(err);
      this.$swal.fire({
        title: "La acción no pudo ser completada",
        text: `Ha ocurrido un error inesperado al intentar procesar la solicitud`,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    },
  },
  components: {
    Nav,
    TitleSection,
  },
};
</script>

<style lang="scss" scoped>
#edit-employee-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}

form {
  min-height: calc(100vh - 3em - 2em - 0.8em - 2em);
  position: relative;
  color: var(--light);
}
</style>
