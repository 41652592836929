import { API, getAuthorization } from "./axiosConfig";

const index = (id_trip) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.get(`trips/${id_trip}/bills`);
};

const show = (id_trip, id_bill) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.get(`trips/${id_trip}/bills/${id_bill}`);
};

const create = (id_trip, params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.post(`trips/${id_trip}/bills`, params);
};

const edit = (id_trip, id_bill, params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.put(`trips/${id_trip}/bills/${id_bill}`, params);
};

const remove = (id_trip, id_bill) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.delete(`trips/${id_trip}/bills/${id_bill}`);
};

export { index, show, create, edit, remove };
