import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const swalOptions = {
  background: "#323232",
  color: "#EDEDED",
  confirmButtonColor: "#3F4B59",
  cancelButtonColor: "#697380",
};

createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2, swalOptions)
  .mount("#app");
