<template>
  <div id="employees-container">
    <div id="main-container">
      <Nav navOptionChosen="employees"></Nav>
      <section class="main-section col-between">
        <div>
          <TitleSection title="Empleados" :loading="loading"></TitleSection>
          <section class="h1_8em row-between">
            <div class="search-container">
              <input
                class="input input-text input-search"
                type="text"
                placeholder="Buscar"
                v-model="search_by"
              />
            </div>
            <router-link class="h100 center" to="/employees/new">
              <img class="h100" src="@/assets/img/new.svg" />
            </router-link>
          </section>
          <table class="table-data">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Teléfono</th>
                <th>Tipo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="employee in employees" :key="employee.id">
                <td>{{ employee.full_name }}</td>
                <td>{{ employee.email }}</td>
                <td>{{ employee.phone }}</td>
                <td>
                  {{
                    employee.user_type_id === 1 ? "Administrador" : "Empleado"
                  }}
                </td>
                <td
                  class="dots"
                  @click="showOption(employee.id)"
                  @focusout="showOption(null)"
                  tabindex="-1"
                >
                  <div class="col-center">
                    <div class="optionDot"></div>
                    <div class="optionDot"></div>
                    <div class="optionDot"></div>
                  </div>
                  <div
                    v-if="optionsActive === employee.id"
                    class="recordOptions"
                  >
                    <div
                      class="recordOption"
                      @click="
                        this.$router.push(`employees/${employee.id}/edit`)
                      "
                    >
                      Editar
                    </div>
                    <div
                      class="recordOption"
                      @click="removeWarning(employee.id, employee.full_name)"
                    >
                      Eliminar
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :per_page="per_page"
          :total_items="total_items"
          :search_by="search_by"
          @new-page="loadTable"
        >
        </Pagination>
      </section>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";
import Pagination from "../../components/Pagination.vue";

// Services
import { index, total_items, remove } from "@/services/users";

export default {
  name: "EmployeesView",
  data: function () {
    return {
      loading: [],
      search_by: null,
      employees: [],
      per_page: 7,
      total_items: null,
      optionsActive: null,
    };
  },
  watch: {
    search_by: function () {
      this.debouncedLoadTable();
    },
  },
  created() {
    this.setPerPage();
    this.loadTable(1);
    this.debouncedLoadTable = _.debounce(() => {
      this.loadTable(1);
    }, 500);
  },
  methods: {
    loadTable: async function (page) {
      this.employees = [];
      this.loading.push(true);
      try {
        let response = await index(this.search_by, this.per_page, page);
        this.employees = response.data.data.filter(
          (employee) => employee.email !== "innovatours10@gmail.com"
        );
        response = await total_items(this.search_by);
        this.total_items = response.data.data.total_items;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading.pop();
      }
    },
    showOption: function (id) {
      if (this.optionsActive != id) this.optionsActive = id;
      else this.optionsActive = null;
    },
    removeWarning: async function (id_user, employee_name) {
      const res = await this.$swal.fire({
        title: `¿Está seguro de eliminar al empleado ${employee_name}?`,
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (res.isConfirmed) this.removeEmployee(id_user);
    },
    removeEmployee: function (id_user) {
      remove(id_user)
        .then(() => {
          this.loadTable(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setPerPage: function () {
      if (window.innerHeight <= 450) this.per_page = 5;
      else if (window.innerHeight <= 600) this.per_page = 6;
      else if (window.innerHeight <= 750) this.per_page = 7;
    },
  },
  components: {
    Nav,
    TitleSection,
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
#employees-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}
</style>
