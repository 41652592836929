<template>
  <div id="pagination-section-container">
    <div id="main-container">
      <div id="pagination-container">
        <div
          :class="current > 1 ? 'btn-active' : 'btn-disable'"
          class="btn-move"
          @click="$emit('new-page', (current -= 1))"
        >
          Atrás
        </div>
        <div id="current">{{ current }}</div>
        <div
          class="btn-move"
          :class="disable_next ? 'btn-disable' : 'btn-active'"
          :disabled="disable_next"
          @click="$emit('new-page', (current += 1))"
        >
          Siguiente
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: { per_page: Number, total_items: Number, search_by: String },
  data() {
    return {
      current: 1,
    };
  },
  watch: {
    search_by: function () {
      this.current = 1;
    },
  },
  computed: {
    disable_next: function () {
      let maxPage = Math.ceil(this.total_items / this.per_page);
      return maxPage === this.current || maxPage === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
#pagination-section-container {
  width: auto;
  height: auto;
}

#main-container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: transparent;
  font-size: 0.8em;
}

#pagination-container {
  width: auto;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  color: var(--light);
  user-select: none;
}

.btn-move {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  margin: 0 1em;
}

.btn-active:hover {
  border-bottom: 2px solid var(--line);
  cursor: pointer;
}

.btn-disable {
  pointer-events: none;
  color: var(--txt-pagination-disabled);
}
</style>
