<template>
  <div id="nav-container">
    <div id="main-container">
      <h1 class="row-center">Innova Tours</h1>
      <div class="row-between">
        <h2>Cuenta:</h2>
        <router-link :to="{ name: 'my-account' }">
          <img id="icon-config" class="center" src="@/assets/img/config.svg" />
        </router-link>
      </div>
      <p id="user-name">{{ user.full_name }}</p>
      <ul id="nav-options">
        <li>
          <router-link
            class="nav-option"
            :class="{ 'nav-option-chosen': navOptionChosen === 'trips' }"
            to="/trips"
          >
            Viajes
          </router-link>
        </li>
        <li>
          <router-link
            class="nav-option"
            :class="{ 'nav-option-chosen': navOptionChosen === 'reservations' }"
            to="/reservations"
          >
            Reservaciones
          </router-link>
        </li>
        <li>
          <router-link
            class="nav-option"
            :class="{ 'nav-option-chosen': navOptionChosen === 'customers' }"
            to="/customers"
          >
            Clientes
          </router-link>
        </li>
        <li v-if="user.user_type_id === 1">
          <router-link
            class="nav-option"
            :class="{ 'nav-option-chosen': navOptionChosen === 'employees' }"
            to="/employees"
          >
            Empleados
          </router-link>
        </li>
        <li v-if="user.user_type_id === 1">
          <router-link
            class="nav-option"
            :class="{ 'nav-option-chosen': navOptionChosen === 'accounting' }"
            to="/accounting"
          >
            Contabilidad
          </router-link>
        </li>
        <li>
          <div class="nav-option" @click="logOut">Cerrar sesión</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavComponent",
  props: ["navOptionChosen"],
  data() {
    return {
      user: { user_type_id: 2 },
    };
  },
  created() {
    this.user = JSON.parse(localStorage.user);
  },
  methods: {
    logOut: function () {
      localStorage.removeItem("user");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
#nav-container {
  width: 25vw;
  max-width: 320px;
  height: auto;
  min-height: 100vh;
}

#main-container {
  width: 100%;
  height: 100%;
  padding: 1em;
  background-color: var(--bg-black);
  color: var(--txt-white);
  font-size: 1em;
}

h1 {
  margin-bottom: 1em;
  font-size: 1.5em;
}

h2 {
  font-size: 0.75em;
  font-weight: bold;
}

#icon-config {
  widows: 1em;
  height: 1em;
  transition: transform 0.2s ease-in-out;
}

#icon-config:hover {
  transform: rotate(45deg);
}

#user-name {
  margin-top: 0.5em;
  font-size: 0.75em;
}

#nav-options {
  margin-top: 6em;
}

.nav-option {
  width: 100%;
  height: 2.4em;
  display: flex;
  justify-content: left;
  align-content: center;
  font-size: 1.2em;
  color: var(--txt-white);
}

.nav-option:hover {
  font-weight: bold;
  cursor: pointer;
}

.nav-option-chosen {
  font-weight: bold;
}

@media (min-width: 1025px) {
  #main-container {
    font-size: 1.3em;
  }
}

@media (min-width: 1200px) {
  #main-container {
    font-size: 1.5em;
  }
}
</style>
