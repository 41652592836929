import { API, getAuthorization } from "./axiosConfig";

const index = (search_by, per_page, page) => {
  API.defaults.headers.Authorization = getAuthorization();
  const config = {
    params: {
      search_by: search_by,
      per_page: per_page,
      page: page,
    },
  };
  return API.get("customers", config);
};

const show = (id_customer) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.get(`customers/${id_customer}`);
};

const total_items = (search_by) => {
  API.defaults.headers.Authorization = getAuthorization();
  const config = {
    params: {
      search_by: search_by,
    },
  };
  return API.get("customers/total-items", config);
};

const create = (params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.post("customers", params);
};

const edit = (id_customer, params) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.put(`customers/${id_customer}`, params);
};

const remove = (id_customer) => {
  API.defaults.headers.Authorization = getAuthorization();
  return API.delete(`customers/${id_customer}`);
};

export { index, show, total_items, create, edit, remove };
