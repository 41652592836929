<template>
  <div id="edit-bill-container">
    <div id="main-container">
      <Nav navOptionChosen="accounting"></Nav>
      <section class="main-section">
        <TitleSection title="Editar gasto" :loading="loading"></TitleSection>
        <form class="col-between" @submit.prevent="update">
          <div>
            <div class="form-control">
              <label class="form-label" for="bill-name">
                Nombre del gasto
              </label>
              <input
                id="bill-name"
                class="center input input-text"
                type="text"
                placeholder="Publicidad"
                v-model="billName"
              />
            </div>
            <div class="form-control">
              <label class="form-label" for="amount">Cantidad</label>
              <input
                id="amount"
                class="center input input-number"
                type="number"
                step="0.01"
                placeholder="100"
                v-model="amount"
              />
            </div>
          </div>
          <div>
            <input
              class="input btn btn-submit"
              type="submit"
              :value="btnValue"
              :disabled="submitDisabled"
            />
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
//Components
import Nav from "../../components/Nav.vue";
import TitleSection from "../../components/TitleSection.vue";

//Services
import { show, edit } from "@/services/tripBills";

export default {
  name: "EditTripBillView",
  data: function () {
    return {
      tripId: this.$route.params.id,
      billId: this.$route.params.idBill,
      loading: [],
      billName: null,
      amount: null,
      btnValue: "Guardar",
      submitDisabled: true,
    };
  },
  created() {
    this.loadBill();
  },
  methods: {
    loadBill: async function () {
      this.loading.push(true);
      try {
        let response = await show(this.tripId, this.billId);
        let res = response.data.data;
        this.billName = res.name;
        this.amount = res.amount;
      } catch (err) {
        this.errorLoadBill();
      } finally {
        this.loading.pop();
        this.submitDisabled = false;
      }
    },
    update: async function () {
      let params = this.getUpdateParams();
      if (params === false) return;
      this.submitDisabled = true;
      this.btnValue = "Guardando";
      this.loading.push(true);
      try {
        await edit(this.tripId, this.billId, params);
        this.$router.push({ name: "trip-bills", params: { id: this.tripId } });
      } catch (err) {
        this.errorUpdate(err);
      } finally {
        this.submitDisabled = false;
        this.btnValue = "Guardar";
        this.loading.pop();
      }
    },
    getUpdateParams: function () {
      if (this.billName == "" || this.amount == "") {
        this.$swal.fire({
          title: `Complete todos los campos para continuar`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      let params = {
        name: this.billName,
        amount: parseFloat(this.amount),
      };
      if (!/^\d+(\.\d+)?$/.test(params.amount)) {
        this.$swal.fire({
          title: `La cantidad "${params.amount}" es inválida`,
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      return params;
    },
    errorLoadBill: function (err) {
      console.log(err);
      this.$swal.fire({
        title: "La acción no pudo ser completada",
        text: `Ha ocurrido un error inesperado al intentar procesar la solicitud`,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    },
    errorUpdate: function (err) {
      console.log(err);
      this.$swal.fire({
        title: "La acción no pudo ser completada",
        text: `Ha ocurrido un error inesperado al intentar procesar la solicitud`,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    },
  },
  components: {
    Nav,
    TitleSection,
  },
};
</script>

<style lang="scss" scoped>
#edit-bill-container {
  width: 100%;
  height: auto;
  background-color: rgb(13, 13, 62);
  color: rgb(144, 144, 144);
}

#main-container {
  width: 100%;
  display: flex;
}

form {
  min-height: calc(100vh - 3em - 2em - 0.8em - 2em);
  position: relative;
  color: var(--light);
}
</style>
