<template>
  <div id="login-container">
    <div id="main-container" class="col-center">
      <h1 class="center">Innova Tours</h1>
      <form class="col-center" @submit.prevent="submitHandler">
        <h2 id="form-title">Iniciar sesión</h2>
        <div class="form-control">
          <label class="form-label" for="email">Correo</label>
          <input
            class="center input input-text"
            type="email"
            autocomplete="email"
            placeholder="example@org.com"
            v-model="email"
          />
        </div>
        <div class="form-control">
          <label class="form-label" for="email">Contraseña</label>
          <input
            class="center input input-text"
            type="password"
            autocomplete="current-password"
            placeholder="Contraseña"
            v-model="password"
          />
        </div>
        <div class="form-control">
          <p class="col-center field-error" v-show="error !== ''">
            {{ error }}
          </p>
        </div>
        <div class="form-control bottom">
          <input
            id="btn-continue"
            class="input center"
            type="submit"
            :value="btnValue"
            :disabled="isDisabled"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// Services
import { signIn } from "@/services/users";

export default {
  name: "LoginView",
  data: function () {
    return {
      email: "",
      password: "",
      btnValue: "Ingresar",
      submitDisabled: false,
      error: "",
    };
  },
  computed: {
    isDisabled() {
      if (this.email !== "" && this.password !== "" && !this.submitDisabled) {
        return false;
      }
      return true;
    },
  },
  methods: {
    submitHandler: async function () {
      this.submitDisabled = true;
      this.btnValue = "Cargando";
      let params = {
        email: this.email,
        password: this.password,
      };
      try {
        let res = await signIn(params);
        localStorage.user = JSON.stringify(res.data.data);
        this.$router.push({ name: "trips" });
      } catch (err) {
        if (err.response) {
          switch (err.response.data.error) {
            case "Invalid credentials":
              this.error = "Correo o contraseña incorrecto";
              break;
          }
        } else if (err.request)
          this.error = "El servidor no ha respondido a la solicitud";
        else this.error = "Ha ocurrido un error inesperado";
      } finally {
        this.submitDisabled = false;
        this.btnValue = "Ingresar";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#login-container {
  width: 100%;
  height: 100vh;
}

#main-container {
  width: 100%;
  height: 100%;
  background-color: var(--bg-black);
}

h1 {
  height: 80px;
  font-size: 24px;
  color: var(--txt-white);
}

form {
  width: 20em;
  height: 400px;
  position: relative;
  padding: 24px;
  border-radius: 6px;
  background-color: var(--bg-dark-blue);
}

#form-title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
  color: var(--light);
}

.form-control {
  width: 245px;
}

input {
  width: 100%;
}

.field-error {
  color: var(--txt-warning);
}

.bottom {
  position: absolute;
  bottom: 24px;
}

#btn-continue {
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  background-color: var(--bg-button);
  color: var(--txt-button);
}

#btn-continue:hover {
  background-color: var(--bg-button-hover);
  cursor: pointer;
}

@media (max-width: 600px) {
  form {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
}
</style>
