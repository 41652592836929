import axios from "axios";

export const API = axios.create({
  baseURL: "https://api.itcolima.com/",
  timeout: 30000,
  headers: {
    Authorization: null,
  },
});

export const getAuthorization = () => {
  let authorization = null;
  if (localStorage.user)
    authorization = JSON.parse(localStorage.user).Authorization;
  return authorization;
};
